import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Layout from "./Layout";
import GtmScript from "../src/Utility/GtmScript";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const ACA_SPH_V11A = lazy(() => import("../src/Components/Pages/Splits/ACA_SPH_V11A"));
const MedicareThankYou = lazy(() => import("../src/Components/Pages/Thankyou/MedicareThankYou"));
const GreencardThankyou = lazy(() => import("../src/Components/Pages/Thankyou/GreencardThankyou"));
const PrivacyPolicy = lazy(() => import("../src/Components/Pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("../src/Components/Pages/TermsOfUse"));
const CookiesPolicy = lazy(() => import("../src/Components/Pages/CookiesPolicy"));
const RedirectWithQueryParams = lazy(() => import("./Utility/RedirectWithQueryParams"));

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  return (
    <>
    <VisitorParamsProvider value={{ visitorParameters }}>
      <RedirectProvider value={{ redirectUrl }}>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* <Layout> */}
                <Route exact path="/" component={HomePage} />
                <Route exact path="/ACA_SPH_V11A" component={ACA_SPH_V11A} />
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYou} />
                <Route exact path={"/greencard-thankyou"} component={GreencardThankyou} />
                <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
                <Route exact path={"/terms-of-use"} component={TermsOfUse} />
                <Route exact path={"/cookies-policy"} component={CookiesPolicy} />
                <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
                <Route path="*"><RedirectWithQueryParams/></Route>
              {/* </Layout> */}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </RedirectProvider>
    </VisitorParamsProvider>
    <GtmScript/>
    </>
  );
};

export default App;
