import React, { lazy } from 'react';


const ACA_SPH_V11A = lazy(() => import('./Components/Pages/Splits/ACA_SPH_V11A'));
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const AppSplitList = (props) => {

    const SplitListMap = {
        'ACA_SPH_V11A': ACA_SPH_V11A,
    };
    
    let SplitComponent;

    if (!(props.splitName in SplitListMap)) {
        SplitComponent = HomePage;
    } else {
        SplitComponent = SplitListMap[props.splitName];
    }
    
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    );

}

export default AppSplitList;